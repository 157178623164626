import React, { useState, useMemo, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { Datagrid, useConfirm } from '@refera/ui-web'
import { useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import {
  DELETE_PROCEDURE_REQUEST,
  EDIT_HELP_REQUEST_PROCEDURE,
  deletePendingRequest,
} from '_modules/helpRequests/actions'
import {
  getHelpRequestPendingListSelector,
  getHelpRequestsByIDSelector,
} from '_modules/helpRequests/selectors'
import { userSelector } from '_modules/authentication/selectors'
import useFetchCall from '_hooks/use-fetch-call'

import ConfirmationDialog from '../modals/confirmation-dialog'
import ProcedureDetailsDialog from '../modals/procedure-details-dialog'
import ModalDialog, { WARNING_MODAL } from '_components/modal/modal-dialog'

import useStyles, { datagridStyle } from './styles'
import { ProceduresColumns } from './constants'

const ProcedureTable = ({ isLoading }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { id: helpRequestId } = useParams()
  const { isConfirmed } = useConfirm()

  // TODO: Remove useStates and add useReducer to get a better readbility and state management
  const userData = useSelector(userSelector)
  const helpRequest = useSelector(getHelpRequestsByIDSelector(helpRequestId))
  const procedures = useSelector(getHelpRequestPendingListSelector)
  const [modalOpen, setModalOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [selectedProcedure, setSelectedProcedure] = useState(null)

  const lastProcedure = useMemo(() => procedures?.[0], [procedures])

  const isDatetimeResolvedValid = useMemo(() => {
    if (!helpRequest?.datetimeResolved) {
      return true
    }

    const datetimeResolved = new Date(helpRequest.datetimeResolved)
    const FIVE_DAYS_IN_MS = 5 * 24 * 60 * 60 * 1000 // 5 dias em milissegundos
    const now = new Date()

    return datetimeResolved.getTime() + FIVE_DAYS_IN_MS > now.getTime()
  }, [helpRequest?.datetimeResolved])

  const handleRemoveProcedure = useCallback(
    async (event, procedure) => {
      event.stopPropagation()
      setMessage('Você tem certeza que deseja remover este trâmite de item de ajuda?')
      const confirmed = await isConfirmed()

      if (confirmed) {
        if (procedure.resolved) {
          setModalDialog({
            isOpen: true,
            subTitle:
              'Você não pode realizar essa ação em um item de ajuda já resolvido. Favor prosseguir criando um novo item de ajuda para o chamado.',
            type: WARNING_MODAL,
          })
        } else if (procedure.user === userData.id) {
          if (procedure?.pendingItems === 1) {
            setModalDialog({
              isOpen: true,
              subTitle: 'O último trâmite não pode ser excluido.',
              type: WARNING_MODAL,
            })
          } else {
            dispatch(deletePendingRequest(helpRequestId, procedure.id))
          }
        } else {
          setModalDialog({
            isOpen: true,
            subTitle: 'Um trâmite só pode ser excluído pelo mesmo usuário que o incluiu.',
            type: WARNING_MODAL,
          })
        }
      }
    },
    [dispatch, userData.id, helpRequestId]
  )

  const handleEditProcedure = useCallback(event => {
    setSelectedProcedure(event.row)
    setModalOpen(true)
  }, [])

  const handleClearPendingDialog = useCallback(() => {
    setModalOpen(false)
    setSelectedProcedure(null)
  }, [])

  const [isDeleteHelpRequestPendingLoading] = useFetchCall(DELETE_PROCEDURE_REQUEST.ACTION)
  const [isEditHelpRequestPendingLoading] = useFetchCall(EDIT_HELP_REQUEST_PROCEDURE.ACTION)

  const isHelpRequestLoading = useMemo(
    () => isDeleteHelpRequestPendingLoading || isEditHelpRequestPendingLoading || isLoading,
    [isDeleteHelpRequestPendingLoading, isEditHelpRequestPendingLoading, isLoading]
  )

  return (
    <>
      <Grid className={styles.mainTable}>
        <Datagrid
          loading={isHelpRequestLoading}
          rows={procedures}
          columns={ProceduresColumns(styles, handleRemoveProcedure, lastProcedure)}
          paginationMode="server"
          sx={datagridStyle}
          onRowClick={handleEditProcedure}
        />
      </Grid>
      <ProcedureDetailsDialog
        onCancel={handleClearPendingDialog}
        open={modalOpen}
        procedure={selectedProcedure}
        title="Adicionar comentário"
        editable={isDatetimeResolvedValid}
      />
      {message && <ConfirmationDialog type="error" subject={message} />}
      <ModalDialog modalDialog={modalDialog} setModalDialog={setModalDialog} />{' '}
    </>
  )
}

export default React.memo(ProcedureTable)

export const MENU_VALUES = {
  SERVICE_ORDER: 'serviceOrder',
  CREATED_AT: 'createdAt',
  AGENCY: 'agency',
  FROM_TO: 'fromTo',
  SUBJECT: 'subject',
  SUBJECT_TRANSLATED: 'subjectTranslated',
  EXPECTED_DEADLINE: 'expectedDeadline',
  STATUS: 'status',
}

export const MENU = [
  {
    label: 'ID',
    value: MENU_VALUES.SERVICE_ORDER,
  },
  {
    label: 'Criado em',
    value: MENU_VALUES.CREATED_AT,
  },
  {
    label: 'Imobiliária',
    value: MENU_VALUES.AGENCY,
  },
  {
    label: 'De/Para',
    value: MENU_VALUES.FROM_TO,
    isAdmin: true,
  },
  {
    label: 'Assunto',
    value: MENU_VALUES.SUBJECT_TRANSLATED,
  },
  {
    label: 'Prazo de execução',
    value: MENU_VALUES.EXPECTED_DEADLINE,
  },
  {
    label: 'Status',
    value: MENU_VALUES.STATUS,
  },
]

export const FROM_TO_OPTIONS = {
  '': '(Todos)',
  refera_to_intermediary: 'Refera para Intermediária',
  intermediary_to_refera: 'Intermediária para Refera',
  tradesman_to_refera: 'Prestador para Refera',
}

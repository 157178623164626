import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  userCircle: {
    display: 'flex',
    height: spacing(32),
    width: spacing(32),
    right: '20px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Theme.Colors.Primary.Base,
    position: 'absolute',
    left: '7.38%',
  },
  menuIcon: {
    fill: Theme.Colors.Grayscale.SixtyFour,
    width: spacing(25),
    height: spacing(25),
    marginRight: spacing(5),
  },
  menuItem: {
    width: '100%',
    height: '16px',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '112.5%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.005em',
    color: Theme.Colors.Grayscale.Eighty,
  },
  menuItems: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '5px 20px',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  logoutIcon: {
    fill: Theme.Colors.Red.Base,
    width: spacing(25),
    height: spacing(25),
    marginRight: spacing(5),
  },
  arrow: {
    fill: Theme.Colors.Grayscale.ThirtyTwo,
    height: '17.34px',
    width: '17.34px',
    left: '8.16px',
    top: '3.33px',
  },
  userLabel: {
    maxWidth: '210px',
    height: 'auto',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    top: '2px',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    position: 'relative',
    left: '16.38%',
    right: '9.38%',
    whiteSpace: 'pre-wrap',
    marginLeft: '8px',
  },
  userIcon: {
    fill: Theme.Colors.Grayscale.Zero,
    height: '16.22px',
    width: '25.27px',
    left: '3.03',
    top: '13.33px',
    right: '9.38%',
  },
  logoutLabel: {
    width: '206px',
    height: '16px',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '112.5%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.005em',
    color: Theme.Colors.Red.Base,
  },
  userCircle1: {
    display: 'flex',
    height: spacing(24),
    width: spacing(24),
    left: '0px',
    top: '0px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  userIcon1: {
    fill: Theme.Colors.Grayscale.Twelve,
    height: '16.22px',
    width: '20.27px',
    left: '3.03',
    top: '13.33px',
    right: '9.38%',
  },
  customMenuItem: {
    '&:not(:first-child)': {
      marginTop: '0px',
      marginBottom: '0px',
      maxHeight: '35px',
    },
    marginTop: '8px',
    marginBottom: '20px',
    maxWidth: '90%',
  },
  profileMenu: {
    display: 'flex',
    flexDirection: 'column',
    margin: '15px',
    padding: '10px 0 10px 0',
    borderTop: '1px solid lightgray',
    borderBottom: '1px solid lightgray',
  },
  profileItem: {
    marginTop: '0px',
    marginBottom: '0px',
    maxWidth: '90%',
    paddingLeft: '0px',
    height: '30px',
  },
  profileIcon: {
    fill: 'lightgrey',
    left: '9.38%',
    right: '9.38%',
    top: '5.21%',
    bottom: '5.21%',
  },
  active: {
    fill: '#7ACB7A',
  },
}))

import React, { useMemo } from 'react'
import { InputLabel, MenuItem, Grid, Select as MUISelect } from '@material-ui/core'
import { InfoCircle as InfoCircleIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { Loader } from '@refera/ui-web'

import useStyles from './styles'

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
}

const Select = ({
  label,
  name,
  onChange,
  value,
  options,
  hint,
  errorMessage,
  isLoading = false,
  ...props
}) => {
  const styles = useStyles()

  const inputProps = useMemo(
    () => ({
      name,
      id: name,
    }),
    [name]
  )

  const labelId = useMemo(() => `${name}-label`, [label])

  return (
    <div className={styles.container}>
      <InputLabel classes={{ root: styles.label }} id={labelId} htmlFor={name}>
        {label}
      </InputLabel>
      <MUISelect
        inputProps={inputProps}
        onChange={onChange}
        value={value}
        labelId={labelId}
        classes={{ root: props?.selectStyles }}
        displayEmpty
        MenuProps={menuProps}
        {...props}
      >
        {isLoading ? (
          <MenuItem
            key=""
            value=""
            classes={{ root: styles.option }}
            ListItemClasses={{ container: styles.optionList }}
          >
            <Grid className={styles.loaderContainer}>
              <Loader open={isLoading} size={20} />
            </Grid>
          </MenuItem>
        ) : (
          options?.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              classes={{ root: styles.option }}
              ListItemClasses={{ container: styles.optionList }}
            >
              {option.label}
            </MenuItem>
          ))
        )}
      </MUISelect>
      {hint && !errorMessage && <span className={styles.hint}>{hint}</span>}
      {errorMessage && (
        <span className={styles.error}>
          <InfoCircleIcon color={Theme.Colors.Red.Base} />
          {errorMessage}
        </span>
      )}
    </div>
  )
}

export default Select

import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ palette, spacing }) => ({
  header: {
    display: 'flex',
    height: spacing(48),
    background: palette.primary.main,
    padding: spacing(0, 26.67),
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    boxShadow: 'null',
    justifyContent: 'space-between',
  },
  default: {
    justifyContent: 'space-between',
  },
  noButtons: {
    justifyContent: 'center',
  },
  logo: {
    width: spacing(32),
    height: spacing(32),
  },
  fullLogo: {
    fontSize: spacing(80),
  },
  logoDivision: {
    width: spacing(1),
    height: spacing(32),
    marginLeft: spacing(22),
    backgroundColor: Theme.Colors.Primary.Dark,
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: 'none',
  },
  leftWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(24),
  },
  logout: {
    fontSize: spacing(14),
    lineHeight: spacing(20),
    fontWeight: 'normal',
    color: 'white',
    textTransform: 'capitalize',
    width: spacing(15),
  },
  support: {
    width: spacing(13.3),
    height: spacing(13.3),
  },
  supportIcon: {
    objectFit: 'contain',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(16),
  },
  searchBar: {
    '& .MuiInputBase-root': {
      height: spacing(32),
    },
  },
  rightContent: {
    display: 'flex',
    gap: spacing(16),
    alignItems: 'center',
  },
  add: {
    background: palette.green.light,
    borderRadius: spacing(24),
    minWidth: spacing(143),
    height: spacing(32),
    textTransform: 'none',
    color: 'white',
    whiteSpace: 'nowrap',

    '&:hover': {
      background: palette.green.light,
      opacity: 0.8,
    },
  },
  iconButton: {
    width: spacing(20.3),
    height: spacing(20.3),
    marginRight: spacing(4.5),
  },
  iconNotification: {
    width: spacing(10),
    lineHeight: spacing(20),
    stroke: '15px',
  },
  circleNotification: {
    stroke: '15px',
  },

  userCircle: {
    display: 'flex',
    height: spacing(32),
    width: spacing(32),
    rigth: '20px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Theme.Colors.Primary.Base,
    position: 'absolute',
    left: '7.38%',
    right: '9.38%',
  },
  lgpdIcons: {
    fill: Theme.Colors.Grayscale.SixtyFour,
    left: '9.38%',
    right: '9.38%',
    top: '5.21%',
    bottom: '5.21%',
  },
  lgpdLabels: {
    width: '206px',
    height: '16px',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '112.5%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.005em',
  },
  logoutIcon: {
    fill: Theme.Colors.Red.Base,
    left: '12.54%',
    right: '47.87%',
    top: '13.54%',
    bottom: '13.54%',
  },
  arrow: {
    fill: Theme.Colors.Grayscale.ThirtyTwo,
    height: '17.34px',
    width: '17.34px',
    left: '8.16px',
    top: '3.33px',
  },
  userLabel: {
    maxWidth: '210px',
    height: 'auto',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    top: '2px',
    flex: 'none',
    order: '1',
    flexGrow: '0',
    position: 'relative',
    left: '16.38%',
    right: '9.38%',
    whiteSpace: 'pre-wrap',
  },
  userIcon: {
    fill: Theme.Colors.Grayscale.Zero,
    height: '16.22px',
    width: '25.27px',
    left: '3.03',
    top: '13.33px',
    right: '9.38%',
  },
  logoutLabel: {
    width: '206px',
    height: '16px',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '112.5%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.005em',
    color: Theme.Colors.Red.Base,
  },
  userLabel1: {
    maxWidth: '200px',
    height: '20px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'fixed',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  userCircle1: {
    display: 'flex',
    height: spacing(24),
    width: spacing(24),
    left: '0px',
    top: '0px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  userIcon1: {
    fill: Theme.Colors.Grayscale.Twelve,
    height: '16.22px',
    width: '20.27px',
    left: '3.03',
    top: '13.33px',
    right: '9.38%',
  },
  userArrow: {
    marginLeft: '10px',
  },
  paper: {
    marginTop: '15px',
    marginLeft: '8px',
  },
  menuList: {
    marginTop: '0px',
    marginBottom: '10px',
  },
  customMenuItem: {
    '&:not(:first-child)': {
      marginTop: '0px',
      marginBottom: '0px',
      maxHeight: '35px',
    },
    marginTop: '8px',
    marginBottom: '20px',
    maxWidth: '90%',
  },
}))

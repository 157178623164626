import React, { useCallback, useMemo } from 'react'
import { Grid, MenuItem, Typography, IconButton } from '@material-ui/core'

import {
  User,
  DocumentText,
  ShieldTick,
  Logout1,
  ArrowRight3,
  Message,
  House2,
  People,
  UserOctagon,
  SecuritySafe,
} from '@refera/ui-icons'

import CircleIcon from '@mui/icons-material/Circle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import useStyles from './styles'
import { ROUTES } from '_/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '_/modules/authentication/selectors'
import { logout, setUserProfile, postAccessHistory } from '_modules/authentication/actions'
import { navigate } from '@reach/router'
import { menuItemsSelector } from '_/modules/menu-items/selector'
import { useToast } from '_/hooks/use-toast'
import useRolePermission from '_/hooks/use-role-permission'
import { getMenuItems } from '_/modules/menu-items/actions'
import { getIntermediaryProfileMenuItem } from './constants'
import { USER_ROLES } from '_/utils/user'

export const HEADER_THEME = {
  DEFAULT: 'default',
  NO_BUTTONS: 'noButtons',
}

const HeaderItems = ({ handleNavigation, handleClose }) => {
  const styles = useStyles()
  const user = useSelector(userSelector)
  const dispatch = useDispatch()
  const hasProfiles = user?.profiles.length > 0
  const profiles = user?.profiles
  const userMenuItems = useSelector(menuItemsSelector)
  const lgpdLinks = user?.lgpdLinks

  const { checkPathPermission, isIntermediary } = useRolePermission()
  const { showToast } = useToast()

  const getMenuSecondary = useMemo(() => {
    const items = userMenuItems?.filter(item => item.type === 'secondary') ?? []

    if (isIntermediary) return [...items, getIntermediaryProfileMenuItem(user?.agency)]
    return items
  }, [userMenuItems, user, user?.agency])

  const handleUserTerms = useCallback(
    item => {
      const link = item === 'term' ? lgpdLinks[0] : lgpdLinks[1]
      window.open(link, '_blank')
      handleClose()
    },
    [lgpdLinks]
  )

  const handleClick = useCallback(
    path => {
      let newPath = path
      const userCanAccessPath = checkPathPermission(path)
      if (!userCanAccessPath) {
        return showToast({
          type: 'error',
          message: 'Você não tem permissão para esta ação.',
        })
      }

      handleClose()
      if (!path.startsWith('/')) {
        newPath = `/${path}`
      }
      return navigate(newPath)
    },
    [checkPathPermission, showToast]
  )

  const handleLogout = useCallback(() => {
    dispatch(logout())
    navigate('/login')
  }, [dispatch])

  const handleSelectProfile = useCallback(
    async name => {
      await dispatch(setUserProfile(user?.authToken, { profile: name })).then(async () => {
        dispatch(getMenuItems())
        await dispatch(postAccessHistory()).then(() => {
          handleNavigation(name === USER_ROLES.TRADESMAN ? ROUTES.TRADESMAN : ROUTES.WIDGETS)
        })
      })

      return null
    },
    [dispatch, user]
  )

  const changeProfile = profile => {
    if (profile !== user?.activeProfile) {
      handleSelectProfile(profile)
    }
  }

  const renderMenuProfile = useMemo(() => {
    return (
      <>
        <MenuItem className={styles.customMenuItem}>
          <Grid className={styles.userCircle}>
            <User className={styles.userIcon} />
          </Grid>
          <Typography className={styles.userLabel}>{user?.name}</Typography>
        </MenuItem>

        <Grid className={styles.profileMenu}>
          {hasProfiles &&
            profiles.map(profileName => {
              if (profileName === USER_ROLES.REFERA) {
                return (
                  <MenuItem
                    className={styles.profileItem}
                    onClick={() => changeProfile(profileName)}
                  >
                    <IconButton disabled>
                      {user?.activeProfile === USER_ROLES.REFERA ? (
                        <CheckCircleIcon className={[styles.profileIcon, styles.active]} />
                      ) : (
                        <CircleIcon className={styles.profileIcon} />
                      )}
                    </IconButton>
                    <Typography className={styles.menuItem}>Acesso Refera</Typography>
                  </MenuItem>
                )
              }

              if (profileName === USER_ROLES.INTERMEDIARY) {
                return (
                  <MenuItem
                    className={styles.profileItem}
                    onClick={() => changeProfile(profileName)}
                  >
                    <IconButton disabled>
                      {user?.activeProfile === USER_ROLES.INTERMEDIARY ? (
                        <CheckCircleIcon className={[styles.profileIcon, styles.active]} />
                      ) : (
                        <CircleIcon className={styles.profileIcon} />
                      )}
                    </IconButton>
                    <Typography className={styles.menuItem}>Acesso Intermediária</Typography>
                  </MenuItem>
                )
              }

              if (profileName === USER_ROLES.TRADESMAN) {
                return (
                  <MenuItem
                    className={styles.profileItem}
                    onClick={() => changeProfile(profileName)}
                  >
                    <IconButton disabled>
                      {user?.activeProfile === USER_ROLES.TRADESMAN ? (
                        <CheckCircleIcon className={[styles.profileIcon, styles.active]} />
                      ) : (
                        <CircleIcon className={styles.profileIcon} />
                      )}
                    </IconButton>
                    <Typography className={styles.menuItem}>Acesso Prestador</Typography>
                  </MenuItem>
                )
              }

              if (profileName === USER_ROLES.LESSEE) {
                return (
                  <MenuItem
                    className={styles.profileItem}
                    onClick={() => changeProfile(profileName)}
                  >
                    <IconButton disabled>
                      {user?.activeProfile === USER_ROLES.LESSEE ? (
                        <CheckCircleIcon className={[styles.profileIcon, styles.active]} />
                      ) : (
                        <CircleIcon className={styles.profileIcon} />
                      )}
                    </IconButton>
                    <Typography className={styles.menuItem}>Acesso Inquilino</Typography>
                  </MenuItem>
                )
              }

              return null
            })}
        </Grid>
      </>
    )
  }, [user])

  const renderIconImage = {
    [ROUTES.BUDGET_TEMPLATE]: <ShieldTick className={styles.menuIcon} />,
    [`/${ROUTES.AGENCY}/${user.agency}`]: <House2 className={styles.menuIcon} />,
    [ROUTES.REFERA_USERS]: <UserOctagon className={styles.menuIcon} />,
    [ROUTES.GROUP_PERMISSIONS]: <SecuritySafe className={styles.menuIcon} />,
    [ROUTES.PROFILES]: <People className={styles.menuIcon} />,
    [ROUTES.CATEGORY]: <Message className={styles.menuIcon} />,
    [ROUTES.PROBLEMS]: <Message className={styles.menuIcon} />,
    [ROUTES.SERVICE]: <Message className={styles.menuIcon} />,
    [ROUTES.STEP_MESSAGE]: <Message className={styles.menuIcon} />,
    [ROUTES.STEP_MESSAGE]: <Message className={styles.menuIcon} />,
  }

  return (
    <>
      {renderMenuProfile}
      {getMenuSecondary.map(item => (
        <button
          key={`side-menu-${item.id}`}
          onClick={() => handleClick(item.path)}
          type="button"
          className={styles.menuItems}
        >
          {renderIconImage?.[`/${item?.path}`] ?? <User className={styles.iconProfile} />}
          <Grid className={styles.menuItem}>
            <Typography className={styles.menuItem}>{item.label}</Typography>
          </Grid>
          <ArrowRight3 className={styles.arrow} />
        </button>
      ))}
      <button
        key="side-menu-terms_user"
        onClick={() => handleUserTerms('term')}
        type="button"
        className={styles.menuItems}
      >
        <DocumentText className={styles.menuIcon} />
        <Grid className={styles.menuItem}>
          <Typography className={styles.menuItem}>Termos de uso</Typography>
        </Grid>
        <ArrowRight3 className={styles.arrow} />
      </button>
      <button
        key="side-menu-terms_user"
        onClick={() => handleUserTerms('policy')}
        type="button"
        className={styles.menuItems}
      >
        <ShieldTick className={styles.menuIcon} />
        <Grid className={styles.menuItem}>
          <Typography className={styles.menuItem}>Política de privacidade</Typography>
        </Grid>
        <ArrowRight3 className={styles.arrow} />
      </button>
      <button
        key="side-menu-terms_user"
        onClick={handleLogout}
        type="button"
        className={styles.menuItems}
      >
        <Logout1 className={styles.logoutIcon} />
        <Grid className={styles.menuItem}>
          <Typography className={styles.logoutLabel}>Sair</Typography>
        </Grid>
      </button>
    </>
  )
}

HeaderItems.defaultProps = {
  className: '',
  theme: HEADER_THEME.DEFAULT,
}

export default React.memo(HeaderItems)
